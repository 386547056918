<template>
  <div class="flex-column" v-loading="loading">
    <el-row :gutter="8">
      <el-col :span="6">
        <el-card id="draggableTreeTotal" class="draggableTree">
          <div style="margin:10px;border-bottom: 1px solid #ccc;padding-bottom: 5px;">
            <p style="margin: 0; width: 20%; float: left;margin-top: 5px;"><span style="color:#1a96d4;margin:0 5px;border:2px solid #1a96d4;text-align: right;"></span>总表</p>
            <el-input style="width:80%" placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
          </div>
          <el-tree ref="tree" :data="newAllTotalMeter" node-key="id" @node-drag-end="handleDragEndTotal" draggable :allow-drop="allowDrop" class="draggableTreeHeight" :filter-node-method="filterNode"> </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-row style="text-align:center;background:#fff;margin: 5px 0 10px;">
          <div style="padding:10px 5px">
            <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('today')">今天</el-button>
            <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('yesterday')">昨天</el-button>
            <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('sevenTime')">最近七天</el-button>
            <el-date-picker v-model="listQuery.timer" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin:0 10px;width: 280px !importantheight: 33px;vertical-align: middle;"> </el-date-picker>
            <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click='handleFilter'>搜索</el-button>
            <el-button class="filter-item" size="mini" type="danger" v-waves icon="el-icon-refresh-righ" @click='reset'>重置</el-button>
          </div>
        </el-row>
        <el-row :gutter="8" style="margin-bottom:10px">
          <el-col :span="8" class="total" >
            <el-card class="Dtop_l_num">
              <p class="topNum_title">总表流量合计</p>
              <animate-number from="0" :to="totalFatherNum" :key="totalFatherNum" duration="2000" :formatter="formatter"></animate-number>
            </el-card>
          </el-col>
          <el-col :span="8" class="total" >
            <el-card class="Dtop_l_num">
              <p class="topNum_title">流量差值</p>
              <animate-number from="0" :to="differenceNum" :key="differenceNum" duration="2000" style="color:red" :formatter="formatter"></animate-number>
            </el-card>
          </el-col>
          <el-col :span="8" class="total" >
            <el-card class="Dtop_l_num">
            <p class="topNum_title">区域流量合计</p>
              <animate-number from="0" :to="totalChildNum" :key="totalChildNum" duration="2000" :formatter="formatter"></animate-number>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-card class="ContrastHeight">
            <p v-if="contrast.length <= 0" class="noData">
              请拖拽总表和区域到此区域查询
            </p>
            <template v-else>
              <p v-for="(i,index) in contrast" class="ContrastList">{{i.label}}</p>
            </template>
          </el-card>
          
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-card id="draggableTree" class="draggableTree">
          <div style="margin:10px;border-bottom: 1px solid #ccc;padding-bottom: 5px;">
            <el-input style="width:80%;float: left;"  placeholder="输入关键字进行过滤" v-model="filterTextArea"> </el-input>
            <!-- <span style="display:block;margin:10px;text-align: right;padding-bottom: 5px;">区域<span style="color:#1a96d4;margin:0 5px;border:2px solid #1a96d4;"></span></span> -->
            <p style="margin: 0; width: 20%; float: left;margin-top: 5px;text-align: right;">区域<span style="color:#1a96d4;margin:0 5px;border:2px solid #1a96d4;text-align: right;"></span></p>
            <div style="clear: both;"></div>
          </div>
          <el-tree ref="treeArea" :data="newTree" node-key="id" @node-drag-end="handleDragEnd" draggable :allow-drop="allowDrop" class="draggableTreeHeight" :filter-node-method="filterNodeArea"> </el-tree>
        </el-card>
      </el-col>
    </el-row>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import basicTable from './table'//表格组件
  import PropertyTreeSelect from './selectTree'

  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      basicTable,
      PropertyTreeSelect
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        contrast:[],//对比数据
        newTree:JSON.parse(JSON.stringify(this.$store.state.AllArea)),//深拷贝数组
        newAllTotalMeter:[],//深拷贝数组所有总表数据
        loading: false,
        allTotalMeter:[],//所有总表数据
        totalFatherNum:0,//总表统计
        totalChildNum:0,//子表统计
        differenceNum:0,//流量差值
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          timer:[],
          treeIds:[],
          key: '',
          beginTime:'',
          endTime:'',
          contrast:[],
        },
        filterText: '',//过滤条件
        filterTextArea: '',//过滤条件 - 区域
      }
    },
    watch: {
      '$store.state.AllArea'(n,o){
        if(n){
          this.newTree = JSON.parse(JSON.stringify(n))
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      },
      // 区域过滤
      filterTextArea(val) {
        this.$refs.treeArea.filter(val);
      }
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      var today = new Date();
      var yesterday = this.$basicFun.getDay(-1);
      this.listQuery.timer = [yesterday,today]
      this.getAllMainMeter()//获取所有大表数据

    },
    methods: {
      // 树形过滤方法
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      // 树形过滤方法 - 区域
      filterNodeArea(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      //树形组件-拖拽结束时（可能未成功）触发的事件 - 总表
      handleDragEndTotal(draggingNode, dropNode, dropType, e){
        let ele=document.getElementById("draggableTreeTotal").offsetWidth - 50;//拖拽树div宽度
        if(e.offsetX >= ele){//拖拽距离足够远,取值第一个div的宽度-50
          draggingNode.data.dataType = 'totalMemter'
          this.contrast.push(draggingNode.data)
          const parent = draggingNode.parent;
          const children = parent.data.children || parent.data;
          const index = children.findIndex(d => d.id === draggingNode.data.id);
          children.splice(index, 1);
        }
      },
      //树形组件-拖拽结束时（可能未成功）触发的事件 - 区域
      handleDragEnd(draggingNode, dropNode, dropType, e){
        let ele=document.getElementById("draggableTree").offsetWidth - 50;//拖拽树div宽度
        if(e.offsetX < 0){//拖拽距离足够远,取值第一个div的宽度-50
          draggingNode.data.dataType = 'area'
          this.contrast.push(draggingNode.data)
          const parent = draggingNode.parent;
          const children = parent.data.children || parent.data;
          const index = children.findIndex(d => d.id === draggingNode.data.id);
          children.splice(index, 1);
        }
      },
      // 树形组件-停止拖拽时节点可放置的位置
      allowDrop(draggingNode, dropNode, type) {
        // 停止拖拽后树节点位置不发生改变
        return false
      },
      //重置
      reset(){
        this.contrast = []
        this.newAllTotalMeter = JSON.parse(JSON.stringify(this.allTotalMeter));
        this.newTree = JSON.parse(JSON.stringify(this.$store.state.AllArea));
        this.totalFatherNum = 0
        this.totalChildNum = 0
        this.differenceNum = 0
      },
      


      formatter: function (num) {
        return num.toFixed(2)
      },
      //获取所有大表数据
      getAllMainMeter(){
        this.loading = true
        basicDataApi.getAllMainMeter().then(response => {
          if(response.code == 200){
            response.result.forEach(i=>{
              i.label = i.surfaceName + i.surfaceNum
            })
            this.allTotalMeter = response.result
            this.newAllTotalMeter = JSON.parse(JSON.stringify(this.allTotalMeter));
          }else{
            this.allTotalMeter = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        })
      },
      
      //选择时间
      chooseDate(time){
        var today = new Date();
        var yesterday = this.$basicFun.getDay(-1);
        var beforeYesterday = this.$basicFun.getDay(-2);
        var sevenTime = this.$basicFun.getDay(-7);
        if(time == 'today'){
          this.listQuery.timer = [yesterday,today]
        }
        if(time == 'yesterday'){
          this.listQuery.timer = [beforeYesterday,yesterday]
        }
        if(time == 'sevenTime'){
          this.listQuery.timer = [sevenTime,today]
        }
        this.handleFilter()
      },
      // 获取数据
      getList() {
        this.totalFatherNum = this.totalChildNum = this.differenceNum = 0//重置为0，动画过度
        if(this.listQuery.timer){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.timer[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.timer[1],'yyyy-MM-dd');
        }else{
          this.listQuery.beginTime = this.listQuery.endTime = ''
        }
        this.listQuery.contrast = this.contrast
        this.loading = true
        basicDataApi.getMterSearch(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.tableHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.totalMemtertableData = response.data.bigData
            this.totalChildMemtertableData = response.data.userData
            this.totalFatherNum = response.data.bigDosage //总表流量合计
            this.differenceNum = response.data.leakageDosage  //流量差值
            this.totalChildNum = response.data.userDosage //分表流量合计
            
          }else{
            this.tableHeaderList = []
            this.totalMemtertableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false;
        }).catch(()=>{
          this.tableHeaderList = []
          this.totalMemtertableData = []
          this.loading = false;
        })
        
      },
      // 搜索
      handleFilter() {
        if(this.contrast.length <= 0){
          this.$notify({
            message: '请拖拽总表和区域查询',
            type: 'warning',
            duration: 2000
          });
          return
        }
        this.listQuery.page = 1
        this.getList()
      },
      
    },
  }

</script>

<style lang="scss" scoped>
  .zzb{
    display: flex;
    flex-direction: column;
  }
  .line{
    height: 20px;
    width: 100%;
    background: #ccc;
  }
  .Dtop_l_num{
    text-align: center;
  }
  .topNum_title{
    font-size: 12px;
    color: #787a7d;
    margin: 0 0 10px 0;
  }

  
  .ContrastHeight{
    height: calc(100vh - 337px);
    overflow-y: scroll;
    .noData{
      text-align: center;
      margin: 0 auto;
      margin-top: 25%;
      color: #909399;
    }
    .ContrastList{
      margin: 5px;
    }
  }
  .draggableTree{
    height: calc(100vh - 110px);
    .draggableTreeHeight{
      height: calc(100vh - 200px);
      overflow-y: auto;
    }
  }
</style>
