var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "draggableTree",
                  attrs: { id: "draggableTreeTotal" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        margin: "10px",
                        "border-bottom": "1px solid #ccc",
                        "padding-bottom": "5px",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            margin: "0",
                            width: "20%",
                            float: "left",
                            "margin-top": "5px",
                          },
                        },
                        [
                          _c("span", {
                            staticStyle: {
                              color: "#1a96d4",
                              margin: "0 5px",
                              border: "2px solid #1a96d4",
                              "text-align": "right",
                            },
                          }),
                          _vm._v("总表"),
                        ]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { placeholder: "输入关键字进行过滤" },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "draggableTreeHeight",
                    attrs: {
                      data: _vm.newAllTotalMeter,
                      "node-key": "id",
                      draggable: "",
                      "allow-drop": _vm.allowDrop,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "node-drag-end": _vm.handleDragEndTotal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "text-align": "center",
                    background: "#fff",
                    margin: "5px 0 10px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { padding: "10px 5px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.chooseDate("today")
                            },
                          },
                        },
                        [_vm._v("今天")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.chooseDate("yesterday")
                            },
                          },
                        },
                        [_vm._v("昨天")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.chooseDate("sevenTime")
                            },
                          },
                        },
                        [_vm._v("最近七天")]
                      ),
                      _c("el-date-picker", {
                        staticStyle: {
                          margin: "0 10px",
                          width: "280px !importantheight: 33px",
                          "vertical-align": "middle",
                        },
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.listQuery.timer,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "timer", $$v)
                          },
                          expression: "listQuery.timer",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleFilter },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "danger",
                            icon: "el-icon-refresh-righ",
                          },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { gutter: 8 },
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "total", attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "Dtop_l_num" },
                        [
                          _c("p", { staticClass: "topNum_title" }, [
                            _vm._v("总表流量合计"),
                          ]),
                          _c("animate-number", {
                            key: _vm.totalFatherNum,
                            attrs: {
                              from: "0",
                              to: _vm.totalFatherNum,
                              duration: "2000",
                              formatter: _vm.formatter,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "total", attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "Dtop_l_num" },
                        [
                          _c("p", { staticClass: "topNum_title" }, [
                            _vm._v("流量差值"),
                          ]),
                          _c("animate-number", {
                            key: _vm.differenceNum,
                            staticStyle: { color: "red" },
                            attrs: {
                              from: "0",
                              to: _vm.differenceNum,
                              duration: "2000",
                              formatter: _vm.formatter,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "total", attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "Dtop_l_num" },
                        [
                          _c("p", { staticClass: "topNum_title" }, [
                            _vm._v("区域流量合计"),
                          ]),
                          _c("animate-number", {
                            key: _vm.totalChildNum,
                            attrs: {
                              from: "0",
                              to: _vm.totalChildNum,
                              duration: "2000",
                              formatter: _vm.formatter,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-card",
                    { staticClass: "ContrastHeight" },
                    [
                      _vm.contrast.length <= 0
                        ? _c("p", { staticClass: "noData" }, [
                            _vm._v(" 请拖拽总表和区域到此区域查询 "),
                          ])
                        : _vm._l(_vm.contrast, function (i, index) {
                            return _c("p", { staticClass: "ContrastList" }, [
                              _vm._v(_vm._s(i.label)),
                            ])
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "draggableTree",
                  attrs: { id: "draggableTree" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        margin: "10px",
                        "border-bottom": "1px solid #ccc",
                        "padding-bottom": "5px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%", float: "left" },
                        attrs: { placeholder: "输入关键字进行过滤" },
                        model: {
                          value: _vm.filterTextArea,
                          callback: function ($$v) {
                            _vm.filterTextArea = $$v
                          },
                          expression: "filterTextArea",
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            margin: "0",
                            width: "20%",
                            float: "left",
                            "margin-top": "5px",
                            "text-align": "right",
                          },
                        },
                        [
                          _vm._v("区域"),
                          _c("span", {
                            staticStyle: {
                              color: "#1a96d4",
                              margin: "0 5px",
                              border: "2px solid #1a96d4",
                              "text-align": "right",
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticStyle: { clear: "both" } }),
                    ],
                    1
                  ),
                  _c("el-tree", {
                    ref: "treeArea",
                    staticClass: "draggableTreeHeight",
                    attrs: {
                      data: _vm.newTree,
                      "node-key": "id",
                      draggable: "",
                      "allow-drop": _vm.allowDrop,
                      "filter-node-method": _vm.filterNodeArea,
                    },
                    on: { "node-drag-end": _vm.handleDragEnd },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }